/** Data */
import { useEffect, useState } from 'react'
import { eventTypes } from '@vidday/data'
import { useEventRecipients } from './useRecipients'
import { useEventContext } from '../contexts/EventProvider'
import eventWithin24h from '../utils/eventWithin24h'

const useInvitationMessage = () => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const [defaultMessage, setDefaultMessage] = useState()
	const isWithin24h = eventWithin24h(event)

	useEffect(() => {
		const defaultStaticMessage = event.guestbook
			? `📔 Welcome to our wedding guest book!\n\nWe're asking guests to submit video messages, photos, and clips from today's celebration to create a beautiful wedding video montage for %recipients%.\n\nPlease take a moment to share your well wishes, memories, and highlights from the day. Your quick submission will help us capture the joy and love of this special occasion.\n\nWith your heartfelt contributions, we'll create a cherished keepsake for the newlyweds to remember their wedding day.\n\nThank you for being part of our special day!`
			: isWithin24h
			? `Hi, you're invited! 👋\n\nToday, we’re asking friends and family to submit video messages and photos to create one beautiful %occasion% video montage, which will be presented to %recipients% as a surprise.\n\nThis is all happening today, so time is of the essence! Your quick submission is key, and it will ensure %recipients% has an unforgettable %occasion% video.\n\nWith your prompt help, we can make this video truly memorable.\n\nThank you for making this day special.`
			: `Hi, you're invited! 👋\n\nWe're asking friends and family to submit video messages wishing %recipients% %occasion-phrase%.\n\nSubmissions are brought together into one beautiful video gift, and it will be presented to %recipients-firstName% as a surprise.\n\nWith your help, %recipients-firstName% will never forget this %occasion% video. Please RSVP if you plan on participating, or submit now if you're ready.\n\nThank you!`

		/**  RECIPIENTS */
		const eventOccasion = eventTypes.filter((el) => el?.type == event.occasion)[0]
		const occasionPhrase =
			event.occasion == 'other' ? `a happy ${event.occasionOther.toLowerCase()}` : eventOccasion?.phrase
		const occasion =
			event.occasion == 'other' ? `${event.occasionOther.toLowerCase()}` : eventOccasion?.label.toLowerCase()
		const staticMessage =
			event.occasion == 'memorial'
				? `Dear friends and loved ones,\n\nAs we remember %recipients%, let us come together to celebrate the life and legacy they have left behind. I invite you all to join me in creating a special and sentimental memorial video on VidDay.\n\nThis video will be a collection of our memories, love, and support, honoring %recipients-firstName%. It will serve as a tribute to their life and the impact they had on each of us.\n\nYou can submit a short video message, or video clips and photos of %recipients-firstName%.\n\nPlease RSVP below if you plan on submitting. Thank you.`
				: defaultStaticMessage

		setDefaultMessage(
			staticMessage
				.replace(/%recipients%/g, `${recipients.formatted}`)
				.replace(/%recipients-firstName%/g, `${recipients.firstname}`)
				.replace(/%occasion-phrase%/g, `${occasionPhrase}`)
				.replace(/%occasion%/g, `${occasion}`)
		)
	}, [recipients, event.occasion, event.guestbook])

	const inviteMessage = !event.inviteMessage || event.inviteMessage === '' ? defaultMessage : event.inviteMessage

	return { defaultMessage, inviteMessage }
}
export default useInvitationMessage
