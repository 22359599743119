import React from 'react'
import { Button } from '@chakra-ui/react'
import { PlaylistAddCheckRounded } from '../Icon'
const InvitationListButton = ({ onClick, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<PlaylistAddCheckRounded />
			Manage
		</Button>
	)
}

export default InvitationListButton
