import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useEventPrompts } from '../../../../../hooks/useEventPrompts'
import { updateEvent } from '../../../../../../api/app/events'
import { chakra, FormLabel, Text } from '@chakra-ui/react'
import PromptsArray from '../../../../molecules/controls/PromptsArray'

const FormPrompts = ({ ...rest }) => {
	const { event } = useEventContext()

	const prompts = useEventPrompts()

	const dispatch = useDispatch()

	const { control, errors, watch, setValue } = useForm({
		defaultValues: {
			prompts: prompts?.length ? prompts : [{ prompt: undefined, custom: false }],
		},
	})

	const { fields, append, remove, insert } = useFieldArray({
		control,
		name: 'prompts',
	})

	// Set initial values from event
	useEffect(() => {
		if (prompts?.length > 0) {
			const array = prompts.length <= 2 ? [...prompts, { prompt: undefined, custom: false }] : prompts
			setValue('prompts', array)
		}
	}, [prompts])

	useEffect(() => {
		// console.log("Fields Changed", fields)
	}, [fields])

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	const deletePromptHandler = (index) => {
		// Check if this prompt was existing initially or if we created a new one
		let data = fields[index]
		let newPrompts = prompts.filter((p) => p.id !== data.id)

		let eventData = {
			id: event.id,
			prompts: newPrompts,
		}
		/** Remove prompt from current the remote if it was already registered */
		if (newPrompts) {
			dispatch(updateEvent(eventData, true))
		}
		/** Remove prompt from the current field array */
		remove(index)
	}

	/** This handle submit is passed down to the form and is executed through the onSubmit of the form itself (Sort of spaghetti....)  */
	const submitPrompts = (data) => {
		if (data) {
			// Set the data to store
			let eventData = {
				id: event.id,
				prompts: data,
			}
			// if things are different save it
			if (data.length > 0 && data.length <= 3) {
				dispatch(updateEvent(eventData, true))
			}
		}
	}

	return (
		<chakra.form w="full" onSubmit={(e) => e.preventDefault()} {...rest}>
			<FormLabel>
				Inspiration for invitees{' '}
				<Text as="span" fontWeight="200">
					(up to 3 prompts)
				</Text>
			</FormLabel>
			<PromptsArray
				control={control}
				errors={errors}
				name="prompts"
				remove={deletePromptHandler}
				insert={insert}
				append={append}
				data={fields}
				onChange={submitPrompts}
			/>
		</chakra.form>
	)
}

export default FormPrompts
