import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schemaRecipients from './schema'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useEventRecipients } from '../../../../../hooks/useRecipients'
import { deleteRecipient, updateRecipient, addRecipient } from '../../../../../../api/app/events'
import { chakra, FormLabel } from '@chakra-ui/react'
import FieldArray from '../../../../molecules/controls/FieldArray'

const FormRecipients = ({ ...rest }) => {
	const { event } = useEventContext()
	const raw_recipients = useEventRecipients()
	const { raw: recipients } = raw_recipients
	const dispatch = useDispatch()

	const { control, errors, watch, setValue, getValues } = useForm({
		defaultValues: {
			recipients: [{ firstName: undefined, lastName: undefined, id: undefined }],
		},
		resolver: yupResolver(schemaRecipients),
	})

	const { fields, append, remove, insert, update } = useFieldArray({
		control,
		name: 'recipients',
		keyName: 'key',
		defaultValue: [{ firstName: undefined, lastName: undefined }],
	})

	/** Watch values from the form */
	const values = watch()

	useEffect(() => {
		recipients && recipients.length > 0 && setValue('recipients', recipients)
	}, [recipients])

	useEffect(() => {
		var formRecipientsChange = () => {
			let difference = false
			if (recipients?.length !== values?.recipients?.length) {
				difference = true
			} else {
				recipients.forEach((r, i) => {
					if (
						r.firstName != values?.recipients[i].firstName ||
						r.lastName != values?.recipients[i].lastName
					) {
						difference = true
					}
				})
			}
			return difference
		}

		var changed = formRecipientsChange()
		// years != values.years
		// setHasChanged(changed)
	}, [values])

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	const deleteRecipientHandler = (index) => {
		// Check if this recipient was existing initially or if we created a new one
		let currentRecipient = values.recipients[index]

		let didExistBefore = recipients.findIndex((el) => el.id === currentRecipient?.id)

		console.log(index, currentRecipient?.id, didExistBefore, recipients)

		/** Remove recipient from current the remote if it was already registered */
		if (currentRecipient && didExistBefore != -1) {
			dispatch(deleteRecipient(currentRecipient.id))
		}
		/** Remove recipient from the current field array */
		remove(index)
	}

	/** This handle submit is passed down to the form and is executed through the onSubmit of the form itself (Sort of spaghetti....)  */
	const submitRecipients = (data) => {
		if (data.recipients) {
			// Loop through recipients being submitted to see which one changed
			data.recipients.forEach(function (el) {
				// Check if that recipient already exist
				if (el.id && el.id !== undefined) {
					var currentRecipient = raw_recipients.raw.find((r) => {
						return r.id == el.id ? r : undefined
					})

					if (currentRecipient !== undefined) {
						// Check if any of the values have changed
						if (el.firstName !== currentRecipient.firstName || el.lastName !== currentRecipient.lastName) {
							if (el.lastName == '') {
								el.lastName = null
							}
							dispatch(updateRecipient(el))
						}
					}
				} else {
					/** Delete id, being undefined. This is set server-side */
					delete el.id
					// to create a new recipient, we need a first name at the least
					if (el?.firstName && el.firstName.trim() !== '') {
						// Link the new recipient to this current event
						el.eventId = event.id
						if (el.lastName == '') {
							el.lastName = null
						}
						// Submit the new recipient as a new entry
						dispatch(addRecipient(el))
					}
				}
			})
		}
	}

	return (
		<chakra.form w="full" {...rest}>
			<FieldArray
				control={control}
				errors={errors}
				name="recipients"
				remove={deleteRecipientHandler}
				insert={insert}
				append={append}
				update={update}
				data={fields}
				onBlur={() => submitRecipients(getValues())}
			/>
		</chakra.form>
	)
}

export default FormRecipients
