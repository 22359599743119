import React, { useEffect, useState } from 'react'
import { chakra, SimpleGrid, Box, IconButton, Text } from '@chakra-ui/react'
import { ClearRounded } from '../../../atoms/Icon'
import Input from '../Input'
import Select from '../Select'

import { defaultPrompts, guestbookPrompts } from '../../../pages/Invitation/atoms/FormPrompts/prompts.data'
import { useEventRecipients } from '../../../../hooks/useRecipients'
import { useEventContext } from '../../../../contexts/EventProvider'

const ControlledPromptsFieldArray = (props) => {
	const { control, errors, data, append, remove, onChange } = props
	const { event } = useEventContext()
	const recipients = useEventRecipients()

	const [promptOptions, setPromptOptions] = useState()

	useEffect(() => {
		const currentPrompts = data.map((p) => p.prompt)
		let r = recipients.firstname
		const options = (event.guestbook ? guestbookPrompts : defaultPrompts).map((el, i) => {
			let opt = el.replace(/%recipient%/g, r)
			// return selected prompts as disabled
			return (
				<option key={i} disabled={currentPrompts.indexOf(el) != -1} value={el}>
					{opt}
				</option>
			)
			// }
		})
		setPromptOptions(
			<>
				{options}
				<option key={'custom'} value={'custom'}>
					Custom
				</option>
			</>
		)
	}, [data])

	// const promptOptions = (item) => {
	// 	let r = recipients.firstname
	// 	const options = defaultPrompts.map((el, i) => {
	// 		let opt = el.replaceAll('%recipient%', r)
	// 		// return selected prompts as disabled
	// 		return (
	// 			<option key={i} disabled={currentPrompts.indexOf(el) != -1} value={el}>
	// 				{opt}
	// 			</option>
	// 		)
	// 		// }
	// 	})

	// 	return (
	// 		<>
	// 			{options}
	// 			<option key={'custom'} value={'custom'}>
	// 				Custom
	// 			</option>
	// 		</>
	// 	)
	// }

	const handleOnChange = (formData, i) => {
		const index = data.findIndex((el) => el?.id == formData?.id)
		let newPrompts = [...data]
		/** If the new prompt doesn't exist yet */
		if (index == -1) {
			console.log('new', formData.prompt)
			/** Adding to array */
			newPrompts.push(formData)
		} else if (index != -1) {
			/** Otherwise, if the prompt exist, we need to update it */
			/** We're merging to collection */
			let newData = formData
			let existingData = data[index]
			newPrompts[index] = { ...existingData, ...newData }
		}
		onChange(newPrompts)
	}

	return (
		<chakra.fieldset w="full">
			<Box>
				{data &&
					data.length > 0 &&
					data.map((item, index) => {
						return (
							<Box w="full" key={item.id} pos="relative">
								<SimpleGrid columns={[1]} spacing="1rem" w="full" mb="1rem">
									<Box pos="relative" pr={item.prompt || item.custom ? '2.4rem' : '0'}>
										{item.custom ? (
											<Input
												maxLength="75"
												control={control}
												errors={errors}
												id={`prompts[${index}].prompt`}
												name={`prompts[${index}].prompt`}
												placeholder="Write a prompt..."
												defaultValue={item.prompt || ''}
												index={index}
												borderRadius="none"
												rightAddon={
													<Text siz="sm" fontSize=".85rem">
														max. 75
													</Text>
												}
												onBlur={(value) => {
													if (item.prompt !== value) {
														handleOnChange(
															{
																prompt: value,
																custom: true,
																id: item.id,
															},
															index
														)
													}
												}}
											/>
										) : (
											<Select
												control={control}
												errors={errors}
												id={`prompts[${index}].prompt`}
												name={`prompts[${index}].prompt`}
												placeholder="Choose a prompt (optional)..."
												defaultValue={item.prompt || ''}
												index={index}
												options={promptOptions}
												onChange={(e) => {
													let value = e?.target?.value
													let isCustom = value == 'custom'
													handleOnChange(
														{
															prompt: isCustom ? '' : value,
															custom: isCustom,
															id: item.id,
														},
														index
													)
												}}
											/>
										)}

										{(item.prompt || item.custom) && (
											<IconButton
												onClick={() => remove(index)}
												backgroundColor="gray.300"
												colorScheme="red"
												height="24px"
												width="24px"
												minW="24px"
												maxW="24px"
												borderRadius="full"
												pos="absolute"
												right="0"
												top="8px"
												aria-label="Remove Prompt"
												icon={<ClearRounded w="1rem" h="1rem" />}
											/>
										)}
									</Box>
								</SimpleGrid>
							</Box>
						)
					})}
				{/* {data && data.length < 3  && (
          <Flex justify="center" w="full">
            <Button variant="ghost" leftIcon={<AddCircleRoundedIcon />} onClick={() => append('')}>
              Another Prompt
            </Button>
          </Flex>
        )} */}
			</Box>
		</chakra.fieldset>
	)
}

export default ControlledPromptsFieldArray
