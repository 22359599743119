import React from 'react'
import { useParams } from 'react-router-dom'
import { HStack } from '@chakra-ui/react'
import NavBar from '../../../../atoms/NavBar'
import BackButton from '../../../../atoms/nav-controls/BackButton'
import InvitationPreviewButton from '../../../../atoms/nav-controls/InvitationPreviewButton'
import InvitationListButton from '../../../../atoms/nav-controls/InvitationListButton'
import { history } from '../../../../../../history'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { useEventContext } from '../../../../../contexts/EventProvider'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'

const Controls = () => {
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)
	const { event } = useEventContext()
	const public_path =
		event.status == 'published' ? handlers.public_root + `?v=${event.privateKey}` : handlers.public_root

	if (public_path === '/e/undefined') {
		console.error('bad public_path in invitation/molecules/Controls/index.js')
	}

	return (
		<NavBar position="fixed">
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => history.push(handlers.root)} />
			</HStack>
			<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
				<InvitationPreviewButton onClick={() => redirectToNextIfWindow(public_path)} />
				{/* <InvitationSendButton onClick={() => history.push()} /> */}
				<InvitationListButton
					onClick={() => {
						redirectToNextIfWindow(handlers.invitation_list)
						// history.push(handlers.invitation_list)
					}}
				/>
			</HStack>
		</NavBar>
	)
}

export default Controls
