import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import InviteListSelector from '../../selectors/InviteListSelector'
import { history } from '../../../../../../history'

const InvitationOptions = ({ event, ...rest }) => {
	const handlers = useEventHandlersV2(event.uuid)
	const redirectInvitationList = () => {
		history.push(handlers.invitation_list)
	}

	return (
		<Box w="full" {...rest}>
			<Box my={['2rem', '2rem']} textAlign="center" mx={['1rem', 0]}>
				<Heading as="h3" size="md">
					Time to invite people!
				</Heading>
			</Box>

			<InviteListSelector onClick={redirectInvitationList} mb="2rem" />
		</Box>
	)
}

export default InvitationOptions
