import React from 'react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

const InviteListSelector = ({ onClick, ...rest }) => {
	return (
		<NavCard onClick={onClick} {...rest}>
			<NavCardCover path="/assets/images/graphics/navigation-cards/vidday-invitation-send-invites" />
			<NavCardContent title="Send Invites" description="Invite people to submit" />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}

export default InviteListSelector
