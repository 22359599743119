import React, { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import { FormErrorMessage, FormLabel, FormHelperText, FormControl, Textarea } from '@chakra-ui/react'

const MessageTextarea = ({
	name,
	control,
	errors,
	defaultValue,
	label,
	placeholder,
	helperText,
	readOnly,
	textAreaProps,
	maxChars,
	...rest
}) => {
	const { setValue } = control

	/** Set default string counts */
	const [charCount, setCharCount] = useState(0)
	const [maxChar] = useState(maxChars || 500)

	let isError, errorMessage
	// Proceed as a normal (non-nested) input
	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}
	if (charCount > maxChar) {
		isError = true
		errorMessage = 'Over character limit'
	}

	/** set default value */
	useEffect(() => {
		if (defaultValue) {
			setValue(name, defaultValue)
		}
	}, [defaultValue])

	/**
	 * Method called to update the value
	 */
	const update = (e) => {
		/** Retrieve the value */
		let text = e.currentTarget?.value || ''

		// let text = e?.currentTarget?.value !== '' ? e?.currentTarget?.value : defaultMessage
		/** Remove double space from value */
		let value = text?.replace(/(\n\n\n\n|\n\n\n|\n\n)/gm, '\n\n')

		/** If the current value is different than the count previously recorded,
		 * then update the current char count */
		if (value?.length != charCount) {
			setCharCount(value.length)
		}

		/** Let's compute the maximum value based on maxChars */
		// let nextValue = value.substring(0, maxChar);

		/** Dispatch the change of value */
		setValue(name, value.toString())
	}

	useEffect(() => {
		/**
		 * Set initial char count value
		 */
		let val = defaultValue?.replace(/(\n\n\n\n|\n\n\n|\n\n)/gm, '\n\n')
		setCharCount(val?.length)
	}, [])

	return (
		<Box w="full" {...rest}>
			<Controller
				id={name}
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={(props) => (
					<FormControl isInvalid={isError}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}

						<Textarea
							maxH="fit-content"
							minH={['340px', '240px']}
							resize="vertical"
							paddingBottom="2.25rem"
							disabled={readOnly}
							id={name}
							placeholder={placeholder}
							ref={props.ref}
							// defaultValue={props.value}
							{...props}
							{...textAreaProps}
							onChange={update}
							onBlur={update}
						/>

						{charCount != null && (
							<FormHelperText
								textAlign="right"
								float="right"
								marginTop="-2rem"
								marginRight="1rem"
								color={charCount > maxChar ? 'red.500' : 'inherit'}>
								{charCount}
								{maxChar && `/${maxChar}`}
							</FormHelperText>
						)}

						{isError ? (
							<FormErrorMessage display="block" textAlign="left">
								{errorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		</Box>
	)
}

export default MessageTextarea
