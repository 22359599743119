export const defaultPrompts = [
	'Share your favorite thing about %recipient%.',
	'Share why you love %recipient%.',
	'Share what you appreciate most about %recipient%.',
	'Share what you respect most about %recipient%.',
	'Share your favorite memory of %recipient%.',
	'Share how your life has been impacted by %recipient%.',
	'Share what you admire most about %recipient%.',
	'Share an important lesson %recipient% taught you.',
]

export const guestbookPrompts = [
	`Share your favorite memory of the couple.`,
	`What piece of advice would you give to the newlyweds?`,
	`Describe the couple in three words.`,
	`What's your wish for the couple's future together?`,
	`Tell us how you met the bride or groom.`,
	`Share a funny or touching story about the couple.`,
	`What moment from today will you remember the most?`,
	`Leave a heartfelt message for the newlyweds.`,
]
