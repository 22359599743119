import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../api/app/events'

/**
 * Hook that accept an event object, and return several attributes
 * @param {*} event
 */
const useCashCollectionGroup = (event) => {
	const dispatch = useDispatch()

	/** Group of participants */
	const [cashCollectionGroup, setCashCollectionGroup] = useState(event?.cashCollectionGroup)

	useEffect(() => {
		if (cashCollectionGroup != event?.cashCollectionGroup) {
			setCashCollectionGroup(event?.cashCollectionGroup)
		}
	}, [event])

	const setGroup = (group) => {
		// Update local state for responsiveness
		setCashCollectionGroup(group)
		// Update value in db!
		dispatch(updateEvent({ id: event.id, cashCollectionGroup: group }))
	}

	return [cashCollectionGroup, setGroup]
}

export default useCashCollectionGroup
