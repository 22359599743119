import { Event } from '@prisma/client'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// Extend dayjs with utc and timezone plugins
dayjs.extend(utc)
dayjs.extend(timezone)

// Function to check if the deadline is within 24 hours from now
const eventWithin24h = (event) => {
	// Convert event deadline to dayjs object
	const deadline = dayjs(event.deadline, event.timezone || dayjs.tz.guess())

	// Get current time
	const currentTime = dayjs()

	// Get the difference between the deadline and current time in hours
	const diffInHours = deadline.diff(currentTime, 'hour')

	// Check if the difference is less than or equal to 24 hours
	return diffInHours <= 24
}

eventWithin24h.propTypes = {
	event: Event,
}

export default eventWithin24h
