/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
	Container,
	Box,
	Heading,
	Text,
	Flex,
	Button,
	Divider,
	VStack,
	useDisclosure,
	useMultiStyleConfig,
} from '@chakra-ui/react'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import FooterBase from '../../molecules/FooterBase'
import { useEventContext } from '../../../contexts/EventProvider'
import Controls from './molecules/Controls'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useEventOccasion } from '../../../hooks/useEventOccasion'
import useCashCollectionGroup from '../../../hooks/useCashCollectionGroup'
import { InvitationCard } from '../../molecules/Envelope'
import InvitationHeader from '../../molecules/InvitationHeader'
import { history } from '../../../../history'
import loadable from '@loadable/component'
import InvitationOptions from './atoms/InvitationOptions'
import FormRecipients from './atoms/FormRecipients'
import FormDeadline from './atoms/FormDeadline'
import FormPrompts from './atoms/FormPrompts'
import { Helmet } from 'react-helmet'
import NavTabs from './molecules/NavTabs'
import { EditRounded, PreviewRounded } from '../../atoms/Icon'
import { updateEvent } from '../../../../api/app/events'
import { useDispatch } from 'react-redux'
import useInvitationMessage from '../../../hooks/useInvitationMessage'
import useNumberSuffix from '../../../hooks/useNumberSuffix'

import { yupResolver } from '@hookform/resolvers/yup'
import schemaMessage from './schema'
import MessageTextarea from '../../molecules/controls/MessageTextarea'
import { showYearsFieldFor } from '../EventDetails/schema'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow.js'

const EventHeader = loadable(() => import(/* webpackPrefetch: true */ '../../organisms/event-header/index.tsx'), {
	ssr: false,
})

const ActionCard = loadable(() => import(/* webpackPrefetch: true */ '../GiftCollection/ActionCard'), { ssr: false })

const Invitation = () => {
	useScrollTopOnMount()
	const dispatch = useDispatch()
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const handlers = useEventHandlersV2(event.uuid)
	const eventOccasion = useEventOccasion()
	const [cashCollectionGroup, handleGroupChange] = useCashCollectionGroup(event)
	const yearSuffix = useNumberSuffix(event.years)

	if (handlers.public_root == '/e/undefined') {
		console.error('bad uuid in Invitation/index.js')
	}
	const redirectPreview = () => redirectToNextIfWindow(handlers.public_root)
	// history.push(handlers.public_root)

	const { defaultMessage, inviteMessage } = useInvitationMessage()

	const { methods, errors, control, getValues, setValue } = useForm({
		mode: 'onBlur',
		defaultValues: {
			inviteMessage: '',
		},
		resolver: yupResolver(schemaMessage('inviteMessage')),
	})

	const handleUpdateEvent = () => {
		const message = getValues('inviteMessage')
		let saveMessage = message
		if (message == '') {
			saveMessage = defaultMessage
			setValue('inviteMessage', defaultMessage)
		}

		// Post message to server
		let postData = {
			id: event?.id,
			inviteMessage: saveMessage,
		}
		dispatch(updateEvent(postData))
	}

	const editRecipients = useDisclosure()
	const styles = useMultiStyleConfig('NavCard')

	return (
		<>
			<Helmet>
				<title>VidDay - Invitation Editor</title>
			</Helmet>

			<Controls />

			<Container maxW="container.md" variant="main">
				<Box w="full" pb="2rem" px={['1rem', 0]}>
					<Box py="2rem" textAlign="center">
						<Heading as="h2" size="xl" variant="hero" mb="1rem">
							Invitation
						</Heading>
						<NavTabs defaultIndex={0} />
					</Box>

					<EventHeader />

					<InvitationCard mb="1rem">
						{/* <InvitationHeader role="creator" event={event} recipients={recipients} /> */}
						<Box w="full" py={'2rem'} px={['1rem', '2rem']}>
							{/* <VStack w="full" spacing="0.25rem" alignItems="center" mb="2rem">
								<Text color="dark" fontSize="1.125rem" fontWeight="bold">
									{event.years && showYearsFieldFor.includes(event.occasion)
										? event.years + yearSuffix
										: ''}{' '}
									{eventOccasion.label} Video for
								</Text>
								{!editRecipients.isOpen && (
									<Heading color="dark" as="h2" variant="hero" size="xl" textAlign="center">
										{recipients.formatted}{' '}
										<Button
											variant="link"
											size="sm"
											position="absolute"
											transform="translate(.5rem, 0.5rem)"
											onClick={() => editRecipients.onToggle()}>
											<EditRounded />
										</Button>
									</Heading>
								)}
							</VStack> */}

							<Box w="full">
								<FormProvider {...methods}>
									{editRecipients.isOpen && (
										<VStack
											w="full"
											spacing="0"
											alignItems="center"
											mb="2rem"
											p="1rem"
											borderRadius=".5rem"
											border={`1px solid var(--vidday-colors-gray-200)`}>
											<FormRecipients mb="1rem" />
											<Button
												variant="outline"
												size="sm"
												minW="8rem"
												onClick={() => editRecipients.onToggle()}>
												Done
											</Button>
										</VStack>
									)}
									<MessageTextarea
										control={control}
										errors={errors}
										name="inviteMessage"
										label="Instructions for invitees"
										defaultValue={inviteMessage}
										maxChars={1000}
										mb="2rem"
										minH={'100px'}
										textAreaProps={{ minH: ['320px'] }}
										onBlur={handleUpdateEvent}
									/>
									<FormPrompts mb="1rem" />
									<FormDeadline />
								</FormProvider>
							</Box>
						</Box>
					</InvitationCard>

					<ActionCard
						mb="2rem"
						recipients={recipients}
						group={cashCollectionGroup}
						handleGroupChange={() =>
							handleGroupChange(cashCollectionGroup == 'creator' ? 'everyone' : 'creator')
						}
					/>

					<Flex justifyContent="center" mb="2rem">
						<Button variant="outline" size="sm" onClick={redirectPreview}>
							<PreviewRounded mr="0.25rem" /> Preview Submission Page
						</Button>
					</Flex>

					<Divider variant="dashed" orientation="horizontal" />

					<InvitationOptions event={event} mb="2rem" />
				</Box>
			</Container>
			<FooterBase />
		</>
	)
}

export default Invitation
