import * as Yup from 'yup'

const schemaRecipients = Yup.object().shape({
	recipients: Yup.array()
		.min(1)
		.of(
			Yup.object().shape({
				firstName: Yup.string().max(30).required('You need to provide a first name.'),
				lastName: Yup.string().max(35).required('You need to provide a last name.'),
				id: Yup.string().nullable().default(null),
			})
		)
		.required(),
})

export default schemaRecipients
